import React from "react";

export default function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.667"
      height="21.669"
      viewBox="0 0 21.667 21.669"
    >
      <path
        id="Path_2814"
        data-name="Path 2814"
        d="M213.387,171.49h-2.664a.775.775,0,0,1-.507-.618c-.157-.68-.336-1.356-.495-2.036a.312.312,0,0,0-.224-.246q-.487-.188-.964-.405a.308.308,0,0,0-.331.014c-.491.3-.988.6-1.485.894a.656.656,0,0,1-.954-.113q-.765-.763-1.527-1.527a.653.653,0,0,1-.12-.938c.3-.507.607-1.015.915-1.52a.258.258,0,0,0,.016-.275q-.229-.5-.428-1.021a.3.3,0,0,0-.236-.208c-.7-.166-1.4-.341-2.093-.515a.639.639,0,0,1-.566-.714q0-1.1,0-2.2a.644.644,0,0,1,.57-.731c.7-.174,1.394-.348,2.092-.517a.276.276,0,0,0,.225-.194c.108-.3.228-.588.357-.876a.262.262,0,0,0-.016-.276q-.524-.861-1.038-1.728a.656.656,0,0,1,.114-.939q.762-.765,1.527-1.527a.651.651,0,0,1,.953-.116c.554.331,1.1.668,1.662.991a.357.357,0,0,0,.271.013c.362-.141.715-.306,1.077-.449a.3.3,0,0,0,.2-.24c.166-.7.341-1.4.514-2.093a.634.634,0,0,1,.7-.56q1.12,0,2.24,0a.636.636,0,0,1,.7.556c.175.7.35,1.407.518,2.113a.282.282,0,0,0,.2.221c.325.125.648.259.965.4a.309.309,0,0,0,.333-.015c.514-.318,1.035-.625,1.554-.936a.655.655,0,0,1,.968.116q.757.756,1.513,1.513a.652.652,0,0,1,.122.952c-.317.531-.64,1.058-.949,1.593a.358.358,0,0,0-.014.271c.128.339.282.668.412,1.005a.3.3,0,0,0,.239.2c.68.161,1.355.339,2.036.5a.776.776,0,0,1,.617.508v2.665a.775.775,0,0,1-.617.508c-.68.158-1.355.335-2.035.5a.3.3,0,0,0-.24.2c-.124.325-.26.647-.4.965a.275.275,0,0,0,.019.294c.32.521.632,1.047.945,1.572a.639.639,0,0,1-.116.92q-.768.772-1.54,1.542a.655.655,0,0,1-.939.112c-.514-.306-1.028-.612-1.537-.925a.272.272,0,0,0-.294-.017q-.512.234-1.037.439a.3.3,0,0,0-.2.24c-.161.679-.338,1.355-.5,2.035A.776.776,0,0,1,213.387,171.49Zm-1.336-14.573a4.238,4.238,0,1,0,4.242,4.234A4.252,4.252,0,0,0,212.051,156.917Z"
        transform="translate(-201.221 -150.321)"
        fill="none"
        stroke="#ffbd00"
        strokeWidth="1"
      />
    </svg>
  );
}
