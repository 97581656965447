import { EvolutionGames } from "./evolutiongames.data";
import { EzugiGames } from "./ezugigames.data";
import { WordCasinoGames } from "./wcogames.data";

export const CasinoProviders = {
  evolution: {
    code: "",
    casino: "ezugi",
    href: "/casino/evolution",
    provider: "EVO_LOBBY",
    homeUrl: "",
    games: EvolutionGames,
  },
  ezugi: {
    href: "/casino/ezugi",
    code: "",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    games: EzugiGames,
  },
  worldcasino: {
    href: "/casino/worldcasino",
    code: "",
    casino: "wco",
    provider: "",
    homeUrl: "",
    games: WordCasinoGames,
  },
};
