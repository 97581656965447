export const WordCasinoGames = {
  baccarat: {
    href: "/casino/worldcasino/baccarat",
    code: "BAC",
    casino: "wco",
    provider: "SN",
    homeUrl: "",
    cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  },

  Avaitor: {
    href: "/casino/worldcasino/Avaitor",
    code: "ATR",
    casino: "wco",
    provider: "PG",
    homeUrl: "",
    cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
  },
};
