import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="container">
        <div className="providers_img">
          <a href="javascript:void(0)" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="javascript:void(0)" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="javascript:void(0)" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="javascript:void(0)" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline">
          <h3
            class="mb-2"
            onClick={() => window.open(`https://wa.me/+917888846060`, "_blank")}
          >
            Call Us {appDetails?.WHATS_APP_URL}
          </h3>
          {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactuss">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrules">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div class="d-flex justify-content-center mt-2">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div>

        <div class="d-flex justify-content-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            class="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <div
                onClick={() =>
                  window.open(`https://wa.me/+917888846060`, "_blank")
                }
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </div>
            </li>
            <li>
              <a target="_blank" href="https://t.me/daddy365">
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100091669203210"
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/officialdaddy365/"
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a className="active" href="/landing-page">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/");
                }}
              >
                <SportsIcon />
                <span>Exchange</span>
              </a>
            </li>
            {/* <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <GamesIcon />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <CashierIcon />
                <span>Deposit</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
