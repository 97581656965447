import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import Whatsapp from "../assets/images/icons/whatsapp.png";
import Instagram from "../assets/images/icons/instagram.png";
import Telegram from "../assets/images/icons/telegram.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon7.png";

import CricketIcon from "../assets/images/icons/cricket.png";
import SoccerIcon from "../assets/images/icons/Soccer.png";
import TennisIcon from "../assets/images/icons/Tennis.png";
import LiveIcon1 from "../assets/images/roulette/auto-roulette.jpg";
import LiveIcon2 from "../assets/images/roulette/Salon-Prive-Roulette.jpg";
import LiveIcon3 from "../assets/images/roulette/turkish-roulette.jpg";
import LiveIcon4 from "../assets/images/roulette/XXXtreme-Lightning-Roulette.jpg";
import LiveIcon5 from "../assets/images/roulette/Speed-Roulette.jpg";
import { useSelector } from "react-redux";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header beforeheader">
      <div className="top_head">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center header-head">
                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                  </a>
                </div>
                <div className="middle-part">
                  <Navbar expand="lg" className="d-none">
                    <Container>
                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                          <Nav.Link href="/landing-page">
                            <HomeIcon /> Home
                          </Nav.Link>
                          <Nav.Link href="/">
                            <SportsIcon /> Exchange
                          </Nav.Link>
                          {/* <Nav.Link href="/sports">
                          <img src={Exchange} alt="Exchange Icon" width={20} />{" "}
                          Exchange
                        </Nav.Link> */}
                          <Nav.Link href="/games-all">
                            <GamesIcon /> Casino
                          </Nav.Link>
                          <Nav.Link href="/cashier">
                            <CashierIcon /> Cashier
                          </Nav.Link>
                        </Nav>
                      </Navbar.Collapse>
                    </Container>
                  </Navbar>
                </div>
                <div className="headerRight">
                  <ul className="social">
                    <li>
                      <a target={"_blank"} href={`${appDetails?.TELEGRAM}`}>
                        <img src={Telegram} />
                      </a>
                    </li>
                    <li>
                      <a target={"_blank"} href={`${appDetails?.TELEGRAM}`}>
                        <img src={Instagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() =>
                          window.open(`https://wa.me/+917888846060`, "_blank")
                        }
                      >
                        <img src={Whatsapp} />
                      </a>
                    </li>
                  </ul>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    className="button-primary btn_signin"
                  >
                    LOGIN
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/mobile-number");
                    }}
                    className="button-primary btn_signup ms-2"
                  >
                    JOIN NOW
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom_head">
        <Container>
          <Row>
            <Col xs={12} className="px-0">
              <ul>
                <li>
                  <a href="javascript:void(0)" className="active">
                    {/* <img src={BotHeadIcon1} alt="bottom head link icon" /> */}
                    <span>Home</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon2} alt="bottom head link icon" /> */}
                    <span>Sports</span>
                  </a>
                  <ul className="navbar-content">
                    <li>
                      <img src={CricketIcon} />
                    </li>
                    <li>
                      <img src={SoccerIcon} />
                    </li>
                    <li>
                      <img src={TennisIcon} />
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon3} alt="bottom head link icon" /> */}
                    <span>Live Games</span>
                  </a>
                  <ul className="navbar-content">
                    <li>
                      <img src={LiveIcon1} />
                    </li>
                    <li>
                      <img src={LiveIcon2} />
                    </li>
                    <li>
                      <img src={LiveIcon3} />
                    </li>
                    <li>
                      <img src={LiveIcon4} />
                    </li>
                    <li>
                      <img src={LiveIcon5} />
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon4} alt="bottom head link icon" /> */}
                    <span>Chess</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon5} alt="bottom head link icon" /> */}
                    <span>Slots</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon6} alt="bottom head link icon" /> */}
                    <span>Lottery</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon7} alt="bottom head link icon" /> */}
                    <span>Fishing</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon7} alt="bottom head link icon" /> */}
                    <span>Promotion</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    {/* <img src={BotHeadIcon7} alt="bottom head link icon" /> */}
                    <span>Mobile</span>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
